import L from 'leaflet'
import MaterialIcon from '../MaterialIcon'
import _ from 'lodash'
import ListenerBridge from '../bridge'
import qs from 'qs'
async function handleIconStyles(context, styles) {
  if (styles.iconType === 'cdn') {
    const relay = context.getParentListener().getParentListener().getParentProvider().getProvider('relay')
    const cdn_contact = relay.getCDNContact()
    const svg_path = await cdn_contact.execute('GET', `?${qs.stringify({ source_id: relay.getConstant('SELF_SOURCE_ID'), name: styles.icon, category: 'icons', variant: 'map', extension: 'svg' })}`,)
    return { ...styles, svg_path, iconType: 'inline' }
  }
  return styles
}
class Marker extends ListenerBridge(L.Marker) {
  getDefaultStyles() {
    return {
      className: 'l-icon-material',
      markerColor: this.getStyle('color'),
      iconColor: 'white',
      circleOutlineColor: 'white',
      circleOutlineWidth: 1,
      outlineColor: 'gray',
      outlineWidth: 0.5,
      iconSize: [48, 48]
    }
  }
  async refreshStyles(prev_styles, new_styles) {
    const styles = await handleIconStyles(this, _.merge({}, this.getDefaultStyles(), this.getStyles()))
    const final_styles = _.merge({ popupAnchor: [0, -styles.iconSize[1]] }, styles)
    this.setIcon(new MaterialIcon(final_styles))
    return super.refreshStyles(prev_styles, new_styles)
  }
}

export default Marker
