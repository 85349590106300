import React, { useMemo } from 'react'
import { withSelector } from '@front/volcanion'

import { InputLabel, FormControl, FormControlLabel, FormGroup, Switch, Checkbox, FormHelperText, Box } from '@mui/material'

import { makeStyles } from '@mui/styles'
import styles from './styles'
const useStyles = makeStyles(styles)
const getValueDecorator = (type) => {
  switch (type) {
    case 'switch':
      return Switch
    case 'standard':
    case 'checkbox':
    default:
      return Checkbox
  }
}

const SwitchComponent = (props, context) => {
  const {
    displayEmpty,
    name,
    value = '',
    label,
    onChange,
    options: inputOptions,
    isLoading,
    selectorProps,
    valueType = 'checkbox',
    size = 'small',
    helperText,
    error,
    inputRef,
    orientation = 'horizontal',
    onInputChange,
    hideUnselectedOption = false,
    hideControlOption = false,
    disabled,
    disableTypography = false,
    clearValue,
    labelPlacement = 'end',
    _label = {},
    _formGroup = {},
    // DEPRECATED
    formLabelProps,
    formGroupProps,
    ...custom
  } = props
  const classes = useStyles(props)
  const {
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    getOptionProps,
    getOptionLabelProps,
    isOptionSelected,
  } = selectorProps


  const Decorator = useMemo(() => getValueDecorator(valueType), [valueType])

  const options = !!hideUnselectedOption && !!disabled
    ? _.filter(inputOptions, option => isOptionSelected(option))
    : inputOptions

  return (
    <FormControl ref={inputRef} name={name} classes={classes} error={error} {...custom}>
      <InputLabel
        id={`${name}-button-group`}
        shrink
        variant="standard"
        sx={{ marginLeft: .3, color: '#000000E6' }}
        {...formLabelProps}
        {..._label}
      >
        {label}
      </InputLabel>
      <FormGroup row={orientation === 'horizontal'} id={`${name}-button-group`} sx={{ pt: !!label ? 3 : 1, pl: 3 }} {...formGroupProps} {..._formGroup}>
        {
          _.map(options, (option) => (
            <FormControlLabel
              key={getOptionKey(option)}
              labelPlacement={labelPlacement}
              componentsProps={{
                typography: {
                  variant: 'subtitle1'
                }
              }}
              disableTypography={disableTypography}
              disabled={disabled}
              control={(!!hideControlOption && !!disabled)
                ? <></>
                : <Decorator
                  checked={isOptionSelected(option)}
                  onChange={() => onChange(getOptionValue(option))}
                  size={size}
                  {...getOptionProps(option)}
                />
              }
              {...getOptionLabelProps(option)}
              label={getOptionLabel(option)}
            />
          ))
        }
      </FormGroup>
      <Box hidden={!!helperText}><FormHelperText>{helperText}</FormHelperText></Box>
    </FormControl>
  );
}

export default React.memo(withSelector(SwitchComponent, { multiple: true, allowDeselect: true, loadOnFocus: false }))
