import ProviderListener from '@front/volcanion/classes/listener';
import _ from 'lodash'
import MapUtils from '../utils/map';
import { SetUtils } from '@front/volcanion/utils';


class LeafletListener extends ProviderListener {
  constructor(...args) {
    super(...args)
    this.boundOnChildEvent = this.onChildEvent.bind(this)
  }
  getLeafletContext() {
    return this._context
  }
  getInstance() {
    return this._instance
  }
  generateLeafletInstance() {
    return null
  }
  isSinglePoint() {
    return false
  }
  generateLeafletContext() {
    switch (this.getOption('listener_type')) {
      case 'popup':
      case 'tooltip':
        return _.merge({}, this.getInfo(), { listener_id: this.getId() })
      case 'marker':
      case 'polyline':
      case 'polygon':
        return _.merge({}, this.getInfo(), { listener_id: this.getId(), overlayContainer: this.getInstance() })
      case 'layer':
        return _.merge({}, this.getInfo(), { listener_id: this.getId(), layerContainer: this.getInstance() })
      case 'control':
        return _.merge({}, this.getInfo(), { listener_id: this.getId(), layersControl: this.getInstance() })
      case 'cluster':
        return _.merge({}, this.getInfo(), { listener_id: this.getId(), clusterContainer: this.getInstance(), layerContainer: this.getInstance() })
      case 'base':
        return null
      case 'map':
      default:
        return { map: this.getInstance(), mapId: this.getParentProvider().getProviderId() }
    }
  }
  fitChildrenBounds(types, recursive) {
    if (this.isControlEnabled()) {
      const positions = _.compact(_.flatten(_.map(this.getChildrenListeners(types || [], recursive), (listener) => listener.getActiveCoordinates())))
      if (!_.isEmpty(positions)) this.getParentMap().fitBounds(positions, this.getOption('boundOptions'))
    }
  }
  onChildEvent(event) {
    if (
      !!this.getOption('fitToBounds') && (
        (event?.name === "layer_added" || event?.name === "layer_removed") ||
        (this.getOption('followBounds') && event?.name === "position_change")
      )
    ) {
      this.fitChildrenBounds(this.getOption('boundOptions.types') || [], this.getOption('boundOptions.recursive') !== false)
    }
    return this.getParentLayer()?.getParentListener()?.onChildEvent(event)
  }
  getRecords() {
    return this.getParentListener()?.getResult()
  }
  onInstanceCreate() {
    return this
  }
  onInstanceDestroy() {
    return this
  }
  onCreate(event) {
    if (this.getOption('config.model_name'))
      this.createModelListener(this.getId(), this.getOption('config.model_name'), { ids: this.getOption('config.ids') }, this.getOption('config'))
    if (!!this.getParentNode())
      this.addCallback(this.getParentNode()?.getParentListener()?.boundOnChildEvent, 'child')
    this._instance = this.generateLeafletInstance()
    this._context = this.generateLeafletContext()
    !!this.isControlled() && this.getParentControl()?.addOverlay(this.getInstance())
    if (!this.isControlled() || (!!this.isControlled() && !!this.isControlDefaultEnabled()))
      this.addToParent()
    this.getInstance().refreshStyles(null, this.getOption('styles'))
    _.map(this.getOption('eventHandlers'), (callback, name) => this.getInstance().on(name, callback, this))
    this.onInstanceCreate(event)
    return this.setIsReady(true)
  }
  onDestroy(event) {
    this.removeCallback(this.getParentNode()?.getParentListener()?.boundOnChildEvent, 'child')
    !!this.isControlled() && this.getParentControl()?.removeLayer(this.getInstance())
    _.map(this.getOption('eventHandlers'), (callback, name) => this.getInstance().off(name, callback, this))
    this.removeFromParent()
    this.setIsReady(false)
    this.onInstanceDestroy(event)
    return super.onDestroy(event)
  }
  onLocalEvent(event) {
    if (event?.name === 'status_updated' && event?.key === 'isReady' && event?.data === true) {
      if (!!this.getOption('fitToBounds'))
        this.fitChildrenBounds(this.getOption('boundOptions.types') || [], this.getOption('boundOptions.recursive') !== false)
    }
    return super.onLocalEvent(event)
  }
  onParentEvent(event) {
    if (event?.name === 'result_updated' && this.getFullListenerStatus()?.isReady) {
      const prev_coordinates = _.map(event.prev_value, this.getOption('config.coordinates_path'))
      this._onPositionChange(_.head(prev_coordinates), this.getActiveCoordinates())
      this.onStylesChange(this.getOption('styles'), this.getOption('styles'))
    }
    if (event?.name === 'status_updated' && event.key === 'isReady' && !!event.data && this.getFullListenerStatus()?.isReady) {
      this._onPositionChange(null, this.getActiveCoordinates())
      this.onStylesChange(this.getOption('styles'), this.getOption('styles'))
    }
    return super.onParentEvent(event)
  }
  getActiveCoordinates() {
    const position = this.getOption('position')
    const positions = this.getOption('positions')
    if (!this.hasValidCoordinates())
      return []
    if (this.isSinglePoint() && !_.isEmpty(position))
      return position || []
    if (!this.isSinglePoint() && !_.isEmpty(positions))
      return positions || []
    if (!!this.getParentListener()) {
      const coordinates = _.map(this.getParentListener()?.getResult(), this.getOption('config.coordinates_path'))
      return this.isSinglePoint() ? _.head(coordinates) || [] : coordinates || []
    }
    return []
  }
  hasValidCoordinates() {
    const position = this.getOption('position')
    const positions = this.getOption('positions')
    if (!_.isEmpty(position))
      return MapUtils.isPositionValid(position)
    if (!_.isEmpty(positions))
      return _.every(positions, (pos) => MapUtils.isPositionValid(pos))
    if (!!this.getParentListener()) {
      const coordinates = _.map(this.getParentListener()?.getResult(), this.getOption('config.coordinates_path'))
      return !_.isEmpty(coordinates) && _.every(coordinates, (pos) => MapUtils.isPositionValid(pos))
    }
    return false
  }
  addToParent() {
    !!this.isControlled() && this.getParentControl()?.enableLayer(this.getInstance())
    if (!!this.hasValidCoordinates()) {
      if (this.getParentClusterLayer() !== this.getParentNode())
        this.getParentNode()?.addLayer(this.getInstance())
      if (this.isControlEnabled())
        this.getParentClusterLayer()?.addLayer(this.getInstance())
      this.notifyCallbacks({ name: 'layer_added', data: { parent: this.getParentNode() } })
      return this.getParentControl()?.notifyCallbacks({ name: 'parent_add', data: { parent: this.getParentNode() } })
    }
  }
  removeFromParent() {
    this.getParentNode()?.removeLayer(this.getInstance())
    this.getParentClusterLayer()?.removeLayer(this.getInstance())
    this.getInstance().remove()
    !!this.isControlled() && this.getParentControl()?.disableLayer(this.getInstance())
    this.notifyCallbacks({ name: 'layer_removed', data: { parent: this.getParentNode() } })
    return this.getParentControl()?.notifyCallbacks({ name: 'parent_remove', data: { parent: this.getParentNode() } })
  }
  getParentMap() {
    return this.getInfoKey('map')
  }
  getParentClusterLayer() {
    return this.getInfoKey('clusterContainer')
  }
  getParentOverlay() {
    return this.getInfoKey('overlayContainer')
  }
  getParentLayer() {
    return this.getInfoKey('layerContainer')
  }
  getParentControl() {
    return this.getInfoKey('layersControl')
  }
  getParentNode() {
    return this.getInfoKey('layerContainer') || this.getInfoKey('map')
  }
  getControlOptions() {
    return this.getOption('control')
  }
  getControlName() {
    return _.get(this.getControlOptions(), 'name')
  }
  getControlGroup() {
    return _.get(this.getControlOptions(), 'group')
  }
  isControlEnabled() {
    if (!this.getParentControl())
      return true
    if (this.getParentControl()?.hasLayer(this.getInstance()))
      return this.getParentControl()?.isLayerEnabled(this.getInstance())
    return this.getParentLayer().isControlEnabled()
  }
  isControlDefaultEnabled() {
    return _.get(this.getControlOptions(), 'defaultEnabled')
  }
  isControlled() {
    return !!this.getControlName()
  }
  onPositionChange(prev_position, new_position) {
    return this.notifyCallbacks({ name: 'position_change', data: { instance: this, prev_position, new_position } })
  }
  _onPositionChange(prev_position, new_position) {
    if (!!this.hasValidCoordinates()) {
      this.onPositionChange(prev_position, this.getActiveCoordinates())
      if (!this.getParentLayer()?.hasLayer(this.getInstance()))
        this.addToParent()
    }
    else if (this.getParentLayer()?.hasLayer(this.getInstance()))
      this.removeFromParent()
    return this
  }
  onStylesChange(prev_styles, new_styles) {
    return this.getInstance().refreshStyles(prev_styles, new_styles)
  }
  onOptionsUpdated(event) {
    const props = event?.data
    const prevProps = event?.prev_value
    if (!_.isEqual(prevProps.position, props.position) || !_.isEqual(prevProps.positions, props.positions))
      this._onPositionChange(prevProps.position, props.position)

    if (this.getOption('fitToBounds') && (!_.isEqual(prevProps.fitToBounds, props.fitToBounds) || !_.isEqual(prevProps.followBounds, props.followBounds)))
      this.fitChildrenBounds(this.getOption('boundOptions.types') || [], this.getOption('boundOptions.recursive') !== false)

    if (!_.isEqual(prevProps.config, props.config) && this.getOption('config.model_name'))
      this.createModelListener(this.getId(), this.getOption('config.model_name'), { ids: this.getOption('config.ids') }, this.getOption('config'))

    this.onStylesChange(prevProps.styles, props.styles)
    _.map(prevProps?.eventHandlers, (callback, name) => this.getInstance().off(name, callback, this))
    _.map(props?.eventHandlers, (callback, name) => this.getInstance().on(name, callback, this))
    return super.onOptionsUpdated(event)
  }
  getChildrenListeners(types, recursive) {
    const direct_children = SetUtils.filter(this.getParentProvider().getListeners(), (l) => this.isListenerDirectChild(l))
    if (!recursive)
      return direct_children
    const nested_children = _.flatten(_.map(direct_children, (listener) => listener.getChildrenListeners(types, recursive)))
    const merged_children = _.flatten([direct_children, nested_children])
    return !_.isEmpty(types) ? _.filter(merged_children, (l) => _.includes(_.flatten(_.compact([types])), l.getOption('listener_type'))) : merged_children
  }
  getChildrenListenersByModel(model_name, record_ids, types, recursive) {
    return _.filter(this.getChildrenListeners(types, recursive), (listener) => listener.getOption('config.model_name') === model_name && _.intersection(listener.getOption('config.ids'), _.flatten([record_ids])).length !== 0)
  }
  getChildrenListenersByTag(tag_name, types, recursive) {
    return _.filter(this.getChildrenListeners(types, recursive), (listener) => listener.getOption('tag') === tag_name)
  }
  isListenerDirectChild(listener) {
    return listener.getParentNode()?.getParentListener() === this
  }
}

export default LeafletListener
