import L from 'leaflet'
class MaterialIcon extends L.Icon {
  createIcon() {
    var options = L.Util.setOptions(this);
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    var icon = document.createElementNS("http://www.w3.org/2000/svg", options.iconType === 'inline' ? "svg" : "text");
    var group = document.createElementNS("http://www.w3.org/2000/svg", "g");
    var circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    var polygon = document.createElementNS('http://www.w3.org/2000/svg', "polygon");
    const iconWidth = options?.iconSize[0]
    const iconHeight = options?.iconSize[1]
    const circleScale = !_.isUndefined(options.circleScale) ? options.circleScale : 0.75
    const iconMargin = !_.isUndefined(options.iconMargin) ? options.iconMargin : 0.75
    svg.setAttribute('viewbox', [0, 0, iconWidth, iconHeight].join(' '))
    svg.setAttribute('width', iconWidth)
    svg.setAttribute('height', iconHeight)

    svg.setAttribute('style', 'margin-left:-' + parseInt(options.iconSize[0] / 2) + 'px; margin-top:-' + options.iconSize[1] + 'px')
    var array = [
      [0, iconHeight * circleScale / 2],
      [iconWidth * circleScale, iconHeight * circleScale / 2],
      [iconWidth * circleScale / 2, iconHeight]
    ];
    for (const value of array) {
      var point = svg.createSVGPoint();
      point.x = value[0];
      point.y = value[1];
      polygon.points.appendItem(point);
    }

    polygon.setAttribute('fill', options.markerColor);
    polygon.setAttribute('stroke', options.outlineColor);
    polygon.setAttribute('stroke-width', options.outlineWidth);
    group.setAttribute('viewbox', [0, 0, iconWidth * circleScale, iconHeight * circleScale].join(' '))
    group.setAttribute('width', iconWidth * circleScale)
    group.setAttribute('height', iconHeight * circleScale)
    circle.setAttribute('fill', options.circleColor || options.markerColor)
    circle.setAttribute('stroke', options.circleOutlineColor || options.markerColor);
    circle.setAttribute('cx', iconWidth * circleScale / 2);
    circle.setAttribute('cy', iconHeight * circleScale / 2);
    circle.setAttribute('r', _.min([iconHeight, iconWidth]) * circleScale / 2);
    if (options.iconType === 'inline') {
      icon = new DOMParser().parseFromString(options.svg_path, "text/xml").getElementsByTagName('svg')[0]
      icon.setAttribute('viewbox', [0, 0, iconWidth * circleScale, iconHeight * circleScale].join(' '))
      icon.setAttribute('width', iconWidth * iconMargin * circleScale)
      icon.setAttribute('height', iconHeight * iconMargin * circleScale)
      icon.setAttribute('x', (iconWidth - (iconWidth * iconMargin)) * 0.5)
      icon.setAttribute('y', (iconHeight - (iconHeight * iconMargin)) * 0.5)
    }
    else {
      icon.textContent = options.icon;
      icon.setAttribute('font-family', 'Material Icons');
      icon.setAttribute('font-size', `${iconWidth * circleScale * iconMargin}px`)
      icon.setAttribute('x', (iconWidth - (iconWidth * iconMargin)) * 0.5)
      icon.setAttribute('y', (iconHeight * circleScale + iconHeight * circleScale * iconMargin) * 0.5);
    }

    icon.setAttribute('fill', options.iconColor)
    group.appendChild(circle)
    group.appendChild(icon)
    svg.appendChild(polygon)
    svg.appendChild(group)
    return svg;

  }
}

export default MaterialIcon
