import _ from 'lodash'


class Callbacks {
  static selectValueHandler(getOptionValue, getOptionLabel, selectCreatableLabel) {
    return function selectValue(value) {
      if (_.isObject(value)) {
        if (!!_.get(value, '_created'))
          return !!selectCreatableLabel ? getOptionLabel(_.omit(value, ['_created'])) : _.omit(value, ['_created'])
        return getOptionValue(value)
      }
      return value
    }
  }
  static onSelectHandler(onChange, multiple, selectValue, reset) {
    return function onSelect(evt, value, reason) {
      evt.stopPropagation()
      if (reason === 'clear') return reset()
      if (!!multiple) {
        if (_.isEmpty(value))
          return onChange(value)
        else return onChange(_.map(value, selectValue))
      }
      return onChange(selectValue(value))
    }
  }
  static onSearchHandler(onInputChange, clearValue, multiple) {
    return function onSearch(evt, value, reason) {
      if (_.includes(['clear'], reason)) return clearValue()
      else if (_.includes(['reset'], reason) && !value && !multiple) return clearValue()
      else if (!_.includes(['reset'], reason) && !!onInputChange) onInputChange(value)
      else return
    }
  }
  static filterOptionsHandler(filter, freeSolo, { createKey, creatable, createObject }) {
    return function filterOptions(options, params) {
      const filtered = !!freeSolo ? options : filter(options, params);
      if (!creatable) return filtered
      const { inputValue } = params;
      const isExisting = options.some((option) => inputValue === option[createKey]);
      if (inputValue !== '' && !isExisting) {
        return _.flatten([filtered, _.merge({}, createObject, {
          _created: true,
          [createKey]: inputValue,
        })])
      }
      return filtered
    }
  }
}

export default Callbacks
